import { getJBob } from './utils';

$(document).ready(function() {
    let diet_editor = $('#edit-diet');
    let jbob = getJBob();
    let running = [];

    if (diet_editor.length != 0) {
        /*
            Funzione che aggiorna la tabella "kCal inseriti nel menu" in
            funzione dei contenuti attuali
        */
        function updateDietTotals()
        {
            let form = $('#editform');
            let panel = $('#currentsummary');
            let url = $('input:hidden[name=calculate_url]').val();
            panel.addClass('reloading');

            running.forEach((x) => x.abort());
            running = [];
            let call = null;

            call = $.ajax({
                url: url,
                method: 'POST',
                dataType: 'HTML',
                data: form.serialize(),
                success: (new_panel) => {
                    let np = $(new_panel);
                    jbob.initElements(np);
                    panel.replaceWith(np);
                }
            });

            running.push(call);

            $('.summary-table').filter('[id^=currentmealsummary]').each((index, el) => {
                let summary = $(el);
                let url = $(el).prev('input[name=calculate_meal_url]').val();
                summary.addClass('reloading');

                call = $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'HTML',
                    data: form.serialize(),
                    success: (new_panel) => {
                        let np = $(new_panel);
                        jbob.initElements(np);
                        summary.replaceWith(np);
                    }
                });

                running.push(call);
            });

            /*
                Controllo il numero di porzioni nel menu.
                Metto qui questa funzione per comodità, per invocarla ogni volta
                che aggiungo o rimuovo una riga (anche se in una tabella che non
                contempla le porzioni)
            */
            let total_portions = 0;
            diet_editor.find('.dynamic-portions input[type=number]').each((index, el) => {
                total_portions += parseInt($(el).val());
            });

            $('.portions-alert').prop('hidden', total_portions == 14);

            /*
                Verifico quali categorie mostrare o meno al fondo della pagina
            */

            let enabled_categories = [];
            diet_editor.find('.dynamic-portions select').each((index, el) => {
                let selected = $(el).val();
                if (selected > 100000) {
                    selected = selected - 100000;
                    enabled_categories.push(selected);
                }
            });

            $('.diet-block[data-category-id]').each((index, block) => {
                block = $(block);

                let cat_id = parseInt(block.attr('data-category-id'));
                if (enabled_categories.indexOf(cat_id) == -1) {
                    block.prop('hidden', true);
                }
                else {
                    block.prop('hidden', false);
                }
            });
        }

        function updateDynamicRow(row, required_kcal)
        {
            let selected = row.find('select').find('option:selected');
            let input = row.find('input[type=number]');
            let kcal100 = selected.attr('data-kcal-100');
            let unit = selected.attr('data-base-weight');

            let kcal = 0;
            let quantity = 0;

            if (required_kcal) {
                let equivalent_quantity = (required_kcal * 100) / kcal100;
                quantity = roundMargin(equivalent_quantity, unit);
                input.val(quantity);
            }
            else {
                if (row.closest('table').hasClass('dynamic-alternatives')) {
                    quantity = roundMargin(input.val(), unit);
                }
                else {
                    quantity = input.val() * unit;
                }
            }

            kcal = Math.round((kcal100 * quantity) / 100);
            row.find('.kcal').text(kcal);
            return input;
        }

        function updateCategoriesData()
        {
            let form = $('#editform');
            let url = $('input:hidden[name=categories_url]').val();

            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'JSON',
                data: form.serialize(),
                success: (categories) => {
                    categories.forEach((cat) => {
                        $('.dynamic-portions').each((table_index, table) => {
                            console.log(cat);
                            $(table).find('select').find('option[value=' + (100000 + cat.id) + ']').attr('data-kcal-100', cat.kcal).attr('data-base-weight', cat.base_weight);
                        });
                    });

                    $('.dynamic-portions').each((table_index, table) => {
                        $(table).find('select').change();
                    });
                }
            });
        }

        function roundMargin(base, margin)
        {
            base = Math.round(base);
            let mod = base % margin;

            if (mod < (margin / 2)) {
                base -= mod;
            }
            else {
                base += margin - mod;
            }

            return base;
        }

        /*
            Interazione dinamica sul pannello dell'obiettivo calorico
        */
        diet_editor.on('click', '.summary-table .trigger-edit', (e) => {
            let button = $(e.currentTarget);
            let panel = button.closest('.summary-table');
            button.toggleClass('editing');
            let editing = button.hasClass('editing');
            panel.find('.editable').prop('contenteditable', editing).toggleClass('editing', editing);

            if (editing == false) {
                let url = button.attr('data-saving-url');

                let data = {
                    _token: $('meta[name=csrf-token]').attr('content'),
                };

                panel.find('.editable').each((index, el) => {
                    el = $(el);
                    let name = el.attr('data-payload-attr');
                    data[name] = el.text();
                });

                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'HTML',
                    data: data,
                    success: (new_panel) => {
                        let np = $(new_panel);
                        jbob.initElements(np);
                        panel.replaceWith(np);

                        $('.reload-diet').click();
                    }
                });
            }
        });

        /*
            Tasto "Rigenera Dieta".
            Dopo aver finito l'elaborazione, triggera il caricamento della
            tabellina "kCal inseriti nel menu" aggiornata
        */
        diet_editor.on('click', '.reload-diet', (e) => {
            e.preventDefault();

            let button = $(e.currentTarget);
            button.addClass('disabled');

            let form = $('#editform');
            form.addClass('reloading');

            $.ajax({
                url: button.attr('href'),
                method: 'POST',
                dataType: 'HTML',
                data: form.serialize(),
                success: (new_form) => {
                    let nf = $(new_form);
                    jbob.initElements(nf);
                    form.replaceWith(nf);
                    updateDietTotals();
                    button.removeClass('disabled');
                }
            });
        });

        /*
            Modificando una quantità in una tabella di alternative, modifico di
            conseguenza tutte le altre quantità per avere una equivalenza
            calorica
        */
        diet_editor.on('keyup', '.dynamic-alternatives input[type=number]', (e) => {
            let input = $(e.currentTarget);
            let row = input.closest('tr');
            let kcal100 = row.find('select').find('option:selected').attr('data-kcal-100');
            let quantity = input.val();
            let required_kcal = Math.round((kcal100 * quantity) / 100);
            row.find('.kcal').text(required_kcal);

            input.closest('tbody').find('tr').not(row).each((index, i) => {
                updateDynamicRow($(i), required_kcal);
            });

            updateDietTotals();
        });

        /*
            Se modifico un alimento in una tabella di alternative, ricalcolo di
            conseguenza tutto il resto
        */
        diet_editor.on('change', '.dynamic-alternatives select', (e) => {
            let input = updateDynamicRow($(e.currentTarget).closest('tr'), 0);
            input.keyup();
        });

        /*
            Se modifico un alimento in una tabella di porzioni, ricalcolo di
            conseguenza la riga
        */
        diet_editor.on('change', '.dynamic-portions select', (e) => {
            let input = updateDynamicRow($(e.currentTarget).closest('tr'), 0);
            input.keyup();
        });

        /*
            Modificando una quantità in una tabella di porzioni, mi limito a
            ricalcolare i totali complessivi
        */
        diet_editor.on('keyup', '.dynamic-portions input[type=number]', (e) => {
            let input = $(e.currentTarget);
            let row = input.closest('tr');
            let kcal100 = row.find('select').find('option:selected').attr('data-kcal-100');
            let portions = input.val();
            let unit = row.find('select').find('option:selected').attr('data-base-weight');
            let required_kcal = Math.round((kcal100 * (portions * unit)) / 100);
            row.find('.kcal').text(required_kcal);

            updateDietTotals();
        });

        /*
            Quando rimuovo una riga da una tabella dinamica possono succedere
            diverse cose, a seconda del tipo di tabella su cui sto operando
        */
        diet_editor.on('jb-table-row-removed', (e, table, row) => {
            updateDietTotals();

            /*
                Se tolgo da una tabella di alimenti coinvolti in una categoria a
                sua volta usata per le porzioni, devo rifare i conti per la
                categoria stessa per mostrare dei valori coerenti.
                Il calcolo aggiornato dello stato della dieta viene già fatto,
                in modo completo (e dunque tenendo conto di questa rimozione),
                da updateDietTotals()
            */
            if (table.hasClass('dynamic-category')) {
                updateCategoriesData();
            }
        });

        /*
            Cliccando i tasti di generazione PDF, implicitamente prima salvo
            tutto il form di edit della dieta e solo dopo rimando effettivamente
            all'URL desiderato
        */
        diet_editor.on('click', '.save-before', (e) => {
            e.preventDefault();
            let btn = $(e.currentTarget);
            let destination = btn.attr('href');
            let form_sel = btn.attr('data-save-form');
            let form = $(form_sel);

            $.ajax({
                url: form.attr('action'),
                method: form.attr('method'),
                data: form.serialize(),
                success: () => {
                    window.location.href = destination;
                }
            });
        });

        diet_editor.on('keyup', '.portion_unit_select', (e) => {
            updateDietTotals();
        });
    }
});
