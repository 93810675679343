import './bootstrap';

import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/timegrid/main.css';

import DataTable from 'datatables.net';
window.DataTable = DataTable;

import 'datatables.net-dt';
import 'datatables.net-bs5';

import 'jquery-ui/ui/version.js';
import 'jquery-ui/ui/widget.js';
import 'jquery-ui/ui/data.js';
import 'jquery-ui/ui/scroll-parent.js';
import 'jquery-ui/ui/widgets/mouse.js';
import 'jquery-ui/ui/widgets/sortable.js';

import './calendar';
import './diet';
import { getJBob } from './utils';

import 'summernote/dist/summernote-bs5';

$(document).ready(function() {
	getJBob();

	const showNavbar = (toggleId, navId, bodyId, headerId) => {
		const toggle = document.getElementById(toggleId),
			nav = document.getElementById(navId),
			bodypd = document.getElementById(bodyId),
			headerpd = document.getElementById(headerId)

		if (toggle && nav && bodypd && headerpd) {
			toggle.addEventListener('click', () => {
				nav.classList.toggle('showsidebar');
				toggle.classList.toggle('bi-x');
				bodypd.classList.toggle('body-pd');
				headerpd.classList.toggle('body-pd');
			})
		}
	}

	showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');

	$('.datatable').DataTable({
		responsive: true,
		fixedHeader: true,
		"pageLength": 50,
	});

    $('.wysiwyg').each((index, item) => {
        $(item).summernote({
            height: 200,
            dialogsInBody: true,
            toolbar: [
                ['style', ['bold']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['link', ['linkDialogShow']]
            ],
        });
    });

	$('button[data-bs-toggle="modal"]').click(function(e) {
		e.preventDefault();
	});

    $('.table.sortable').each((index, item) => {
        $(item).sortable({
            items: 'tr',
            handle: '.handle',
        });
    });

    $('.lockable').click((e) => {
        $(e.currentTarget).prop('disabled', true);
    });

	/*
		Registrazione
	*/

	$('#register-form').each((index, item) => {
		let form = $(item);
		form.attr('action', window.location);
		form.find('button[type=submit]').prop('disabled', false);
	});

	/*
		Interazioni questionario
	*/

    $('.question input[type=radio]').change(function() {
        let radio = $(this);
        let checked = radio.prop('checked');
        let target = radio.closest('.question').find('input[type=text]');

        if (radio.hasClass('input-enabler')) {
            target.prop('disabled', checked == false).prop('required', checked);
        }
        else if (radio.hasClass('handle-more')) {
            let cell = radio.closest('.form-check');
            let cells_count = radio.closest('.row').find('.form-check').length - 1;
            if (cell.index() == cells_count) {
                target.prop('disabled', checked == false).prop('required', checked);
            }
            else {
                target.prop('disabled', checked).prop('required', checked == false);
            }
        }
        else {
            if (checked) {
                target.prop('disabled', true).prop('required', false);
            }
        }
	});

    $('.question input[type=checkbox]').change(function() {
        let radio = $(this);
        let checked = radio.prop('checked');
        let target = radio.closest('.question').find('input[type=text]');

        if (radio.hasClass('input-enabler')) {
            target.prop('disabled', checked == false).prop('required', checked);
        }
	});

	/*
		Prenotazioni
	*/

	let isbooking = $('#doing_booking').length != 0;

	if (isbooking) {
		$('#doing_booking').on('click', '.days a', function(e) {
			e.preventDefault();
			$(this).closest('.col').find('.btn-primary').toggleClass('btn-outline-primary btn-primary');
			$(this).toggleClass('btn-outline-primary btn-primary');
			let date = $(this).attr('data-date');
			let doctor_id = $('input[name=doctor_id]').val();

			let hours = $('.hours');
			hours.find('.empty-selection').remove();
			hours.find('.d-grid').empty();

			$('input[name=availability_id]').val('0');
			$('button[type=submit]').text("Seleziona prima un giorno e un'ora per procedere").prop('disabled', true);

			$.ajax({
				url: '/disponibilita/query',
				method: 'GET',
				dataType: 'JSON',
				data: {
					for: doctor_id,
					date: date,
				},
				success: function(data) {
					let hours = $('.hours');
					data.forEach(function(d) {
						hours.find('.d-grid').append('<a class="btn btn-outline-primary btn-lg" href="#" data-availability="' + d.id + '">' + d.hour + '</a>');
					});
				}
			});
		});

		$('#doing_booking').on('click', '.hours a', function(e) {
			e.preventDefault();
			$(this).closest('.col').find('.btn-primary').toggleClass('btn-outline-primary btn-primary');
			$(this).toggleClass('btn-outline-primary btn-primary');

			let availability_id = $(this).attr('data-availability');
			$('input[name=availability_id]').val(availability_id);

			$('button[type=submit]').text('Salva').prop('disabled', false);
		});
	}

	/*
		Interazioni acquisti
	*/

	$('.select-product').click(function(e) {
		e.preventDefault();
		$(this).closest('.row').find('.selected-product').toggleClass('selected-product text-bg-success text-bg-light');
		$(this).closest('.card').toggleClass('selected-product text-bg-success text-bg-light');
	});

	$('.verify-coupon').click(function(e) {
		e.preventDefault();
		let code = $('input[name=coupon]').val().trim();
		$(this).addClass('disabled');

		$.ajax({
			method: 'GET',
			url: '/pagamenti/coupon',
			dataType: 'JSON',
			data: {
				coupon: code,
			},
			success: function(data) {
				let feedback = $('.coupon-feedback');
				feedback.removeClass('d-none');

				if (data.status == 'ok') {
					$('.product').each(function() {
						let amount = $(this).attr('data-price');
						let discount = amount * (data.percentage / 100);
						let final_price = (amount - discount).toFixed(2);
						$(this).attr('data-price', final_price);
						$(this).find('.card-footer .price_original').addClass('discounted');
						$(this).find('.card-footer .price_coupon').text(final_price + ' €');
					});

					$('input[name=coupon]').prop('disabled', true);
					feedback.removeClass('alert-danger').addClass('alert-success').text('Coupon valido!');
					$('.verify-coupon').remove();
				}
				else {
					$('.verify-coupon').removeClass('disabled');
					feedback.addClass('alert-danger').text('Coupon non valido!');
				}
			}
		});
	});

	let paypal_button = $('#paypal-button-container');
	if (paypal_button.length > 0) {
		function testPaymentFormFilled(actions, notify)
		{
			let taxcode = $('input[name=taxcode]').val();
			let billing = $('input[name=billing]').val();

			if (taxcode == '' || billing == '') {
				if (actions) {
					actions.disable();
				}

				if (notify) {
					alert("Devi specificare sia il codice fiscale che l'indirizzo di fatturazione");
				}
			}
			else {
				if (actions) {
					actions.enable();
				}
			}
		}

		paypal.Buttons({
			onInit: (data, actions) => {
				testPaymentFormFilled(actions);

				$('input[name=taxcode], input[name=billing]').change(function() {
					testPaymentFormFilled(actions);
				});
			},
			onClick: () => {
				testPaymentFormFilled(null, true);
			},
			createOrder: (data, actions) => {
				$('.payment-panel').prop('inert', true);

				return actions.order.create({
					intent: 'CAPTURE',
					purchase_units: [{
						amount: {
							value: $('.selected-product').attr('data-price'),
						}
					}],
					application_context: {
						shipping_preference: 'NO_SHIPPING',
					},
				});
			},
			onApprove: (data, actions) => {
				return actions.order.capture().then(function(orderData) {
					const transaction = orderData.purchase_units[0].payments.captures[0];

					if (transaction.status == 'COMPLETED') {
						$.ajax({
							url: '/prenotazioni/conferma',
							method: 'POST',
							dataType: 'JSON',
							data: {
								_token: $('meta[name=csrf-token]').attr('content'),
								transaction: transaction.id,
								status: transaction.status,
								amount: $('.selected-product').attr('data-price'),
								product: $('.selected-product').attr('data-id'),
								taxcode: $('input[name=taxcode]').val(),
								billing: $('input[name=billing]').val(),
								coupon: $('input[name=coupon]').val(),
							},
							success: function(d) {
								window.location.href = '/home';
							}
						});
					}
				});
			},
			onCancel(data) {
				$('.payment-panel').prop('inert', false);
			},
			onError(err) {
				$('.payment-panel').prop('inert', false);
			}
		}).render('#paypal-button-container');
	}
});
