import '@fullcalendar/core/vdom';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import itLocale from '@fullcalendar/core/locales/it';

$(document).ready(function() {
	var calendar_node = $('#calendar');
	if (calendar_node.length > 0) {
		let userid = $('input[name=currentuser]').val();

		let calendar = new Calendar(calendar_node.get(0), {
			/*
				Onde evitare grattacapi, tutti gli orari si intendono sempre UTC
			*/
			timeZone: 'UTC',

			plugins: [timeGridPlugin],
			locale: itLocale,
			height: 'auto',
			initialView: 'timeGridWeek',
			allDaySlot: false,
			nowIndicator: false,
			slotMinTime: $('input[name=start_working_hour]').val() + ':00:00',
			slotMaxTime: $('input[name=end_working_hour]').val() + ':00:00',
			events: '/disponibilita/json?for=' + userid,
			slotDuration: '00:30:00',
			headerToolbar: {
				left: 'prev,next today',
				center: 'title',
			},
			eventClick: function(info) {
				console.log(info.event);
				if (info.event.backgroundColor == 'red') {
					window.location.href = info.event.url;
				}
				else {
					$.ajax({
						url: '/disponibilita/toggle',
						method: 'GET',
						dataType: 'JSON',
						data: {
							for: userid,
							start: info.event.start.toUTCString(),
							end: info.event.end.toUTCString(),
						},
						success: function(data) {
							info.event.setProp('id', data.id);
							info.event.setProp('color', data.color);
							info.event.setProp('textColor', data.textColor);
						}
					});
				}
			}
		});

		calendar.render();
	}
});
