import jBob from 'jbob';

let jbob = null;

export function getJBob()
{
    if (jbob == null) {
        jbob = new jBob();
        jbob.init({
            fixBootstrap: ['Tooltip'],
        });
    }

    return jbob;
}
